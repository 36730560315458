import React from 'react';
import './CallToAction.css'; // Importez le fichier CSS pour styliser le composant

const CallToAction = () => {
  return (
    <div className="cta-banner">
      <div className="accueil-overlay d-flex justify-content-center align-items-center">
        <div className="cta-content">
            <h2>Vous voulez découvrir des solutions unique ??</h2>
            <a href="#contact" className="btn btn-xl text-uppercase cta-button">Rejoignez-nous</a>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
