import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // Import AOS animations
import AOS from 'aos';
import './About.css'; // Fichier CSS pour styliser le composant

import Image from '../../assets/portfolio/1.jpeg';

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 800, delay: 200 });
  }, []);

  return (
    <section id="about" className="about-section py-5">
      <div className="container">
        {/* Titre principal et sous-titre */}
        <div className="text-center mb-5">
          <h2 className="about-title" data-aos="fade-down">Qui somme nous ??</h2>
          {/* <p className="about-subtitle" data-aos="fade-down">
            Découvrez qui nous sommes et ce que nous faisons.
          </p> */}
        </div>

        {/* Contenu principal */}
        <div className="row align-items-center">
          {/* Colonne image */}
          <div className="col-lg-3 text-center">
            <img
              src={Image}
              alt="Notre équipe"
              className="about-image shadow"
            />
          </div>

          {/* Colonne description */}
          <div className="col-lg-9 pe-5">
            <h1 className="display-4 font-weight-bold">
                DIGIT AFRIQ PLUS
            </h1>
            <p className="about-description">
                Est une structure informatique qui a vu le jour en novembre 2022.
            </p>
            <p className="about-description">
                Elle a pour <strong>vision</strong> d’être parmi les leaders en matière de solution numérique et d’étendre sa notoriété sur le plan national et sous régional.
            </p>
            <p className="about-description">
                Son domaine d’expertise est le graphisme, la conception et la commercialisation des progiciels de gestion, la
                maintenance. DAP offre aussi des formations dans des domaines spécifiques comme le graphisme et autres...
            </p>
          </div>

        </div>
      </div>
    </section>
  );
};

export default About;
