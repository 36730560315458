// Footer.js
import React from 'react';
import './Footer.css';
import Newsletter from './Newsletter';
import BackToTop from './BackToTop'; // Importer le composant BackToTop

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white py-4">
      <div className="container-fluid footer-content">
        {/* Sections du footer */}
        <div className="footer-section about col-md-4 mb-4">
          <h2 className="h5">À propos de nous</h2>
          <p>Nous sommes une entité spécialisée dans le graphisme, la conception et la commercialisation des progiciels de gestion, la
          maintenance</p>
        </div>

        <div className="footer-section contact col-md-4 mb-4">
          <h2 className="h5">Contact</h2>
          <p><strong>Adresse :</strong> Ilot : 159, Quartier ; Fifatin, Parcelle : O, Maison : MACHIOUDI YAYA</p>
          <p><strong>Email :</strong> <a href="mailto:exemple@email.com" className="text-white">www.digitafriqplus.com</a></p>
          <p><strong>Téléphone :</strong> +229 46615161/45885810</p>
        </div>

        <div className="footer-section links col-md-4 mb-4">
          <h2 className="h5">Liens rapides</h2>
          <ul className="list-unstyled">
            <li><a href="#accueil" className="text-white">Accueil</a></li>
            <li><a href="#about" className="text-white">À propos</a></li>
            <li><a href="#services" className="text-white">Services</a></li>
            <li><a href="#portfolio" className="text-white">Portfolio</a></li>
            <li><a href="#team" className="text-white">Équipe</a></li>
            <li><a href="#contact" className="text-white">Contact</a></li>
          </ul>
        </div>

        <div className="footer-section social col-md-4 mb-4">
          <h2 className="h5">Newsletter</h2>

          <Newsletter />

          <div className="social-icons mt-3">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-white me-3">
              <i className="bi bi-facebook fs-2 "></i>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-white me-3">
              <i className="bi bi-twitter fs-2 "></i>
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-white me-3">
              <i className="bi bi-linkedin fs-2 "></i>
            </a>
            <a href="https://github.com" target="_blank" rel="noopener noreferrer" className="text-white me-3">
              <i className="bi bi-github fs-2 "></i>
            </a>
          </div>

        </div>
      </div>
      <div className="footer-bottom text-center mt-4">
        &copy; {new Date().getFullYear()} L'Afrique Digitalisée | Tous droits réservés
      </div>

      <BackToTop />  {/* Ajouter le bouton Back to Top */}
    </footer>
  );
};

export default Footer;
