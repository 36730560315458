// Newsletter.js
import React, { useState } from 'react';

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email soumis:', email);
    // Ajoutez la logique pour soumettre l'email à un service
  };

  return (
    <div className="newsletter-form mt-3">
      <form onSubmit={handleSubmit} className="d-flex">
        <input
          type="email"
          className="form-control"
          placeholder="Entrez votre email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="btn btn-primary">Envoyer</button>
      </form>
    </div>
  );
};

export default Newsletter;
