// Accueil.js
import React from 'react';
import './Accueil.css';  // Gardons les styles personnalisés

const Accueil = () => {
  return (
    <section className="accueil" id="accueil">
      <div className="accueil-overlay d-flex justify-content-center align-items-center">
        <div className="accueil-content text-center text-white p-4">
          <h1 className="display-4 font-weight-bold">
            Bienvenue sur <span className="brand-name">DIGIT AFRIQ PLUS</span>
          </h1>
          <p className="lead mt-3">Solutions numériques pour les GE - PME - PMI et particuliers d'Afrique</p>
          <a className="btn btn-xl text-uppercase js-scroll-trigger" href="#about">QUI SOMMES NOUS ?</a>
        </div>
      </div>
    </section>
  );
};

export default Accueil;
