import React, { useEffect } from 'react';
import './Contact.css'; 
import 'aos/dist/aos.css'; // Import AOS animations
import AOS from 'aos';

const Contact = () => {
  useEffect(() => {
    AOS.init({ duration: 800, delay: 200 });
  }, []);

  return (
    <div id="contact" className="contact-section py-5">
      <div className="text-center mb-5">
        <h2 className="contact-title" data-aos="fade-down">Contact</h2>
        <p className="contact-subtitle" data-aos="fade-down">Restons en contact pour répondre à vos besoins</p>
      </div>

      <div className="container">

        <div className="row text-center mb-3">
          <div className="col-lg-4 contact-grid-border">
            <div className="contact-info-item">
              <i className="bi bi-geo-alt contact-icon"></i>
              <h5>Adresse</h5>
              <p>Ilot : 159, Quartier ; Fifatin, Parcelle : O, Maison : MACHIOUDI YAYA</p>
            </div>
          </div>

          <div className="col-lg-4 contact-grid-border">
            <div className="contact-info-item">
              <i className="bi bi-telephone contact-icon"></i>
              <h5>Téléphone</h5>
              <p>+229 46615161 / 45885810</p>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="contact-info-item">
              <i className="bi bi-envelope contact-icon"></i>
              <h5>Email</h5>
              <p>www.digitafriqplus.com</p>
            </div>
          </div>
        </div>

        {/* Formulaire de contact */}
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <form className="contact-form p-4">
                <div className="row">
                    <div className="col-lg-6 col-md-12 form-group mb-3">
                        <input type="text" className="form-control" id="name" placeholder="Votre nom" />
                    </div>
                    <div className="col-lg-6 col-md-12 form-group mb-3">
                        <input type="email" className="form-control" id="email" placeholder="Votre email" />
                    </div>
                    <div className="col-lg-12 col-md-12 form-group mb-3">
                        <input type="text" className="form-control" id="subject" placeholder="Sujet de votre message" />
                    </div>
                    <div className="col-lg-12 col-md-12 form-group mb-3">
                        <textarea className="form-control" id="message" rows="4" placeholder="Votre message"></textarea>
                    </div>
                </div>
                <div className="text-center">
                    <button type="submit" className="btn-form btn">Envoyer</button>
                </div>
              
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
