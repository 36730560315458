import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css'; // Import AOS animations
import './Portfolio.css';
import AOS from 'aos';

// Importer les images dans src
import Image1 from '../../assets/portfolio/6.jpeg';
import Image2 from '../../assets/portfolio/8.JPG';
import Image3 from '../../assets/portfolio/4.jpeg';
import Image4 from '../../assets/portfolio/5.JPG';
import Image5 from '../../assets/portfolio/3.jpeg';
import Image6 from '../../assets/portfolio/7.PNG';
import Image7 from '../../assets/portfolio/1.jpeg';
import Image8 from '../../assets/portfolio/9.JPG';
import Image9 from '../../assets/portfolio/10.JPG';
import Image10 from '../../assets/portfolio/11.JPG';
import Image11 from '../../assets/portfolio/2.jpg';
import Image12 from '../../assets/portfolio/12.jpg';

const portfolioData = [
  { id: 1, category: 'Progiciels - Web', image: Image1, title: 'Gestion d\'entreprise' },
  { id: 2, category: 'Progiciels - Web', image: Image2, title: 'Site vitrine de PSW' },
  { id: 3, category: 'Graphisme', image: Image3, title: 'Visuel de PSW' },
  { id: 4, category: 'Progiciels - Web', image: Image4, title: 'Gestion Evènementiel' },
  { id: 5, category: 'Graphisme', image: Image5, title: 'Visuel de PSW' },
  { id: 6, category: 'Progiciels - Web', image: Image6, title: 'Site de gestion' },
  { id: 7, category: 'Graphisme', image: Image7, title: 'Conception Graphique' },
  { id: 8, category: 'Evènementiel', image: Image8, title: 'Gestion Evènementiel' },
  { id: 9, category: 'Inventaires', image: Image9, title: 'Inventaire Au Magasin' },
  { id: 10, category: 'Inventaires', image: Image10, title: 'Inventaire Boutique' },
  { id: 11, category: 'Progiciels - Web', image: Image11, title: 'PSales Web' },
  { id: 12, category: 'Vidéosurveillance', image: Image12, title: 'Caméra de surveillance' },
];

const categories = [
  'Tous', 'Progiciels - Web', 'E-Commerce', 'Maintenance Réseaux', 'Vidéosurveillance',
  'Vente de Matériels', 'Marketing', 'Graphisme', 'Communication', 'Tourisme',
  'Evènementiel', 'Formations', 'Inventaires'
];

const Portfolio = () => {
  useEffect(() => {
    AOS.init({ duration: 800, delay: 200 }); // Initialiser AOS
  }, []); 

  const [activeCategory, setActiveCategory] = useState('Tous');
  const [filteredPortfolio, setFilteredPortfolio] = useState(portfolioData);

  useEffect(() => {
    if (activeCategory === 'Tous') {
      setFilteredPortfolio(portfolioData);
    } else {
      setFilteredPortfolio(portfolioData.filter(item => item.category === activeCategory));
    }
  }, [activeCategory]);

  return (
    <div id="portfolio" className="portfolio-section">
      <div className="text-center">
        <h2 className="portfolio-title" data-aos="fade-down">Portfolio</h2>
        <p className="portfolio-subtitle" data-aos="fade-down">Découvrez nos projets réalisés dans divers domaines</p>
      </div>
      
      <div className="container portfolio-filter text-center mt-4 py-2 rounded">
        {categories.map((category, index) => (
          <button
            key={index}
            onClick={() => setActiveCategory(category)}
            className={`filter-btn ${activeCategory === category ? 'active' : ''}`}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="container">
        <div className="row mt-5">
            {filteredPortfolio.map((item, index) => (
                <div className="col-lg-3 mb-3" key={item.id} data-aos="fade-right" data-aos-delay={`${index * 100}`}>
                    <div key={item.id} className="portfolio-card">
                        <div className="portfolio-image-wrapper">
                            <img src={item.image} alt={item.title} className="portfolio-image" />
                            <div className="portfolio-overlay">
                                <h3 className="portfolio-card-title px-4 text-center">{item.title}</h3>
                                <p className="portfolio-card-category px-4 text-center">{item.category}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
