// Services.js
import React, { useEffect } from 'react';
import './Services.css';
import 'aos/dist/aos.css'; // Import AOS animations
import AOS from 'aos';
import { Carousel } from 'react-bootstrap';

const servicesData = [
  { icon: 'bi bi-laptop', title: 'Progiciels - Web', description: 'Développement de progiciels pour des solutions web sur mesure : sites vitrines, applications complexes, e-commerce.' },
  { icon: 'bi bi-cart', title: 'E-Commerce', description: 'Lancez et développez votre boutique en ligne avec nos solutions complètes pour une expérience fluide.' },
  { icon: 'bi bi-router', title: 'Maintenance Réseaux', description: 'Surveillance et maintenance de vos réseaux pour garantir la disponibilité continue des services.' },
  { icon: 'bi bi-camera-video', title: 'Vidéosurveillance', description: 'Solutions de vidéosurveillance pour sécuriser vos locaux avec des systèmes avancés de détection.' },
  { icon: 'bi bi-laptop', title: 'Vente de Matériels', description: 'Fourniture de matériel informatique de haute qualité : ordinateurs, équipements réseau, accessoires.' },
  { icon: 'bi bi-bullseye', title: 'Marketing', description: 'Stratégies personnalisées pour accroître votre visibilité en ligne et atteindre vos objectifs marketing.' },
  { icon: 'bi bi-palette', title: 'Graphisme', description: 'Design graphique pour des visuels percutants : logos, supports marketing, visuels web.' },
  { icon: 'bi bi-megaphone', title: 'Communication', description: 'Stratégies de communication pour renforcer votre présence en ligne et hors ligne.' },
  { icon: 'bi bi-geo-alt', title: 'Tourisme', description: 'Solutions pour les entreprises touristiques : sites web, gestion des réservations, promotion de destinations.' },
  { icon: 'bi bi-calendar-event', title: 'Evènementiel', description: 'Services complets pour organiser et gérer vos événements professionnels et privés.' },
  { icon: 'bi bi-book', title: 'Formations', description: 'Programmes de formation sur mesure pour développer les compétences de vos équipes.' },
  { icon: 'bi bi-archive', title: 'Inventaires', description: 'Gestion des stocks pour optimiser les opérations logistiques et le suivi des inventaires.' },
];

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 800, delay: 200 });
  }, []);

  return (
    <section id="services" className="services-section py-5">
      <div className="container">
        <div className="text-center mb-4">
          <h2 className="services-title" data-aos="fade-down">Nos Services</h2>
          <p className="services-subtitle" data-aos="fade-down" data-aos-delay="100">
            Découvrez ce que nous pouvons faire pour vous
          </p>
        </div>

        <Carousel indicators={true} interval={3000} wrap={true}>
          {/* Slide 1 */}
          <Carousel.Item>
            <div className="row">
              {servicesData.slice(0, 4).map((service, index) => (
                <div className="col-md-3 mb-4" key={index}>
                  <div className="card service-card h-100 text-center shadow-sm">
                    <div className="card-body">
                      <i className={`${service.icon} service-icon`}></i>
                      <h5 className="card-title mt-3">{service.title}</h5>
                      <p className="card-text">{service.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Carousel.Item>

          {/* Slide 2 */}
          <Carousel.Item>
            <div className="row">
              {servicesData.slice(4, 8).map((service, index) => (
                <div className="col-md-3 mb-4" key={index}>
                  <div className="card service-card h-100 text-center shadow-sm">
                    <div className="card-body">
                      <i className={`${service.icon} service-icon`}></i>
                      <h5 className="card-title mt-3">{service.title}</h5>
                      <p className="card-text">{service.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Carousel.Item>

          {/* Slide 3 */}
          <Carousel.Item>
            <div className="row">
              {servicesData.slice(8, 12).map((service, index) => (
                <div className="col-md-3 mb-4" key={index}>
                  <div className="card service-card h-100 text-center shadow-sm">
                    <div className="card-body">
                      <i className={`${service.icon} service-icon`}></i>
                      <h5 className="card-title mt-3">{service.title}</h5>
                      <p className="card-text">{service.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
};

export default Services;
