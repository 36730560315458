// import logo from './logo.svg';
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Accueil from './components/contents/Accueil';
import About from './components/contents/About';
import Services from './components/contents/Services';
import Portfolio from './components/contents/Portfolio';
import CallToAction from './components/contents/CallToAction';
import Team from './components/contents/Team';
import Contact from './components/contents/Contact';
// import './App.css';

function App() {
  return (
    <div>
      <Header />

      {/* Section Accueil */}
      <Accueil /> 

      {/* Section About */}
      <About />

      {/* Section Services */}
      <Services />

      {/* Section Portfolio */}
      <Portfolio />

      {/* Sections Blog*/}

      {/* Sections CallToAction*/}
      <CallToAction />

      {/* Sections Team*/}
      <Team />

      {/* Sections Contact*/}
      <Contact />

      <Footer />

    </div>
  );
}

export default App;
